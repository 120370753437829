import { createStore } from 'vuex'
export interface State {
  menuConfig: any; 
}
const store =  createStore({
  state: {
    menuConfig: localStorage.getItem('menuConfig')
    ? JSON.parse(localStorage.getItem('menuConfig') as string).menuConfig
    : {IsAdmin: false, IsTeamsLead: false, IsLogin: false, LabelAccount: ''},
  },
  getters: {
    getMenuConfig: (state) => state.menuConfig,
  },
  mutations: {
    setMenuConfig(state, config) {
      state.menuConfig = config;
      localStorage.setItem('menuConfig', JSON.stringify(state.menuConfig));
    },
    updateSesionItem(state, updatedItem: any) {      
      state.menuConfig.IsLogin = updatedItem.IsLogin; 
      state.menuConfig.LabelAccount = updatedItem.LabelAccount;             
      state.menuConfig.Usuario = updatedItem.Usuario; 

    },
  },

  
  actions: {
    fetchMenuConfig({ commit }) {
      // Aquí podrías hacer una llamada a una API o cualquier otra lógica para obtener la configuración
      const config = { 
        LabelAccount: "Mi Cuenta",
        Moneda: "", 
        listaMoneda: ["DOP"],        
        Idioma: "ES", 
        ListaIdiomas: ["ES"],       
        IsAdmin: false,
        IsLogin: false,
        IsTeamsLead: false,
        itemAdmin: [
          {name: "Administrador", visible: true, to: null, items:[
            {name: "Gestión Usuarios", visible: true, to: "/usuarios", items:[]},            
            {name: "Administrar mi equipo", visible: false, to: "/MyTeams", items:[]}
          ]}, 
          {name: "Gestionar Eventos", visible: true, to: null, items:[
            {name:"Control Asistencias", visible: true, to: "/Eventos/Asistencia/LectorQR", items:[] }, 
            {name: "Gestión Eventos", visible: true, to: "/Eventos/adm", items:[]}
            
          ]}, 
          {name: "Reportes", visible: true, to:null,  items:[
            {name:"Reporte de Inscritos", visible: true, to: "/Reportes", items:[] }, 
            {name:"Reporte Evento detalle", visible: true, to: "/Reportes/Detalle/Evento", items:[] }, 
            {name:"Transacciones Cardnet", visible: true, to: "/Reportes/Pagos/Portal/Cardnet/001", items:[] }              
            ]
        }
        ],       
        items: [
          { name: 'Inicio', visible: true, to:"/" },
          { name: 'Eventos', visible: false, to:"/e" },
          { name: 'Precios', visible: false, to:"/pricing" },
          { name: 'Contacto', visible: false, to:"/about" },
        ],
      };
      commit('setMenuConfig', config);
    },

    editConfigItem({ commit }, updatedItem: any) {
      commit('updateSesionItem', updatedItem);
    },
  },
  modules: {
  }
})

store.subscribe((mutation, state) => {
  // Guardar el estado completo en localStorage después de cada mutación
  localStorage.setItem('menuConfig', JSON.stringify(state));
});

export default store;
